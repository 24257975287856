<!-- notifications -->
<template>
  <div class="section-nopad">
    <Section :title="title" :hasFooter="false">
      <ul class="cardcontent-info">
        <li v-for="(item, index) in data" :key="index">
          <div class="lable">{{ item.label }}</div>
          <i class="iconfont iconedit" @click="handleEdit(item)"></i>
        </li>
      </ul>
    </Section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      title: "Companies",
      data: [
        { label: "Businesses", value: "businesses" },
      ],
    };
  },
  methods: {
    handleEdit(item) {
      this.$router.push({
        name: "tablePage",
        query: { title: item.label, moduleType: item.value, moduleName: "companies", activeIndex: "settingCompanies", },
      });
    },
  },
  created() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>